import { Component, inject, OnInit } from "@angular/core";
import { ToastService } from "./shared/services/toast.service";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { AppConnectionService } from "./core/services/app-connection.service";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { LocalStorageService } from "./core/services/local-storage.service";
import { OnBoardingComponent } from "./core/components/on-boarding/on-boarding.component";
import { LanguageService } from "./core/services/language.service";
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { ThemeService } from "./core/services/theme.service";
import { EnvironmentBadgeComponent } from "./dev/components/environment-badge/environment-badge.component";
import { filter } from "rxjs";
import { scan } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NotificationToastComponent } from "./shared/components/notification-toast/notification-toast.component";
import { environment } from "src/environments/environments";
import { EnvironmentNames } from "src/environments/enviroments.enum";
import { TranslocoService } from "@jsverse/transloco";
import { BlockUIModule } from "primeng/blockui";
import { ToastNotificationService } from "./shared/services/toast-notification.service";
import { ToastNotificationType } from "./shared/enums/toast-notification-type.enum";
import { NotificationType } from "./shared/enums/notification-type.enum";
import { RoutingHistoryService } from "./core/services/routing-history.service";
import { RoutingHistoryItem } from "./core/types/routing-history-item.type";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  providers: [],
  standalone: true,
  imports: [
    RouterOutlet,
    LoaderComponent,
    EnvironmentBadgeComponent,
    OnBoardingComponent,
    NotificationToastComponent,
    BlockUIModule,
  ],
})
export class AppComponent implements OnInit {
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _toastService = inject(ToastService);
  private readonly _toastNotificationService = inject(ToastNotificationService);
  private readonly _messageService = inject(MessageService);
  private readonly _appConnectionService = inject(AppConnectionService);
  private readonly _languageService = inject(LanguageService);
  private readonly _themeService = inject(ThemeService);
  private readonly _router = inject(Router);
  private readonly _primeNgConfig = inject(PrimeNGConfig);
  private readonly _translocoService = inject(TranslocoService);
  private readonly _routingHistoryService = inject(RoutingHistoryService);
  hasOnboardingBeenShown =
    this._localStorageService.getItem("hasOnboardingBeenShown") === "true"
      ? true
      : false;
  activatedRoute = "";
  renderEnvironmentBadge = false;
  blockUI: boolean = false;

  constructor() {
    this.renderEnvironmentBadge = environment.name !== EnvironmentNames.PROD;

    this.watchRouterEvents();
    this.addToastOptionsToMessageService();
    this.onConnectionLost();
  }
  ngOnInit(): void {
    this._languageService.onInitilize();
    this._themeService.onInitilize();
    this._primeNgConfig.ripple = true;
    this._translocoService
      .selectTranslateObject("primeng")
      .subscribe(res => this._primeNgConfig.setTranslation(res));
  }

  watchRouterEvents() {
    const historyLimit = 10;

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        scan((history, event) => {
          const navigationEndEvent = event as NavigationEnd;
          const url = navigationEndEvent.url;
          this.activatedRoute = url;
          const updatedHistory = [...history, { url, timestamp: Date.now() }];
          if (updatedHistory.length > historyLimit) {
            updatedHistory.shift();
          }
          return updatedHistory;
        }, [] as RoutingHistoryItem[]),
        takeUntilDestroyed()
      )
      .subscribe(history => {
        this._routingHistoryService.addRoutingHistory(history);
      });
  }

  addToastOptionsToMessageService() {
    this._toastService.$toastObservable
      .pipe(takeUntilDestroyed())
      .subscribe(toastOptions => {
        this._messageService.add(toastOptions);
      });
  }

  onConnectionLost() {
    this._appConnectionService.$isOffline
      .pipe(takeUntilDestroyed())
      .subscribe(isOffline => {
        this.blockUI = isOffline;
        if (isOffline) {
          this._toastNotificationService.showToast(
            NotificationType.APP_CONNECTION_FAILED,
            {
              severity: ToastNotificationType.error,
              life: 0,
              sticky: true,
              closable: false,
            }
          );
        } else {
          this._toastNotificationService.clearToasts();
        }
      });
  }
}
