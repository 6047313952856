<div class="w-full h-screen overflow-hidden bg-secondary-200">
  <app-loader />
  <router-outlet />
  <app-notification-toast />
  <p-blockUI [blocked]="blockUI" />

  @defer (when renderEnvironmentBadge) {
    @if (renderEnvironmentBadge) {
      <app-environment-badge />
    }
  }

  @defer (when !hasOnboardingBeenShown) {
    @if (
      activatedRoute.includes("chat") ||
      (activatedRoute.includes("manager") && !hasOnboardingBeenShown)
    ) {
      <app-on-boarding />
    }
  }
</div>
