import {
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from "@angular/common/http";
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  PreloadAllModules,
  RouterModule,
  provideRouter,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { APP_ROUTES } from "./routes/app.routes";
import { provideTransloco } from "@jsverse/transloco";
import { provideTranslocoMessageformat } from "@jsverse/transloco-messageformat";
import { provideTranslocoLocale } from "@jsverse/transloco-locale";
import { TranslocoHttpLoader } from "./providers/transloco.loader";
import { CaptchaModule } from "./shared/modules/captcha/captcha.module";
import { provideCore } from "./providers/core.provider";
import { TourConsoleModule } from "ngx-ui-tour-console";
import { MessageService } from "primeng/api";

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      CaptchaModule,
      BrowserModule,
      RouterModule,
      AngularSvgIconModule.forRoot(),
      TourConsoleModule
    ),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    provideAnimationsAsync(),
    MessageService,
    provideCore(),
    provideRouter(
      APP_ROUTES,
      withRouterConfig({ onSameUrlNavigation: "reload" }),
      withViewTransitions(),
      withPreloading(PreloadAllModules)
    ),
    provideTransloco({
      config: {
        availableLangs: ["en", "tr"],
        defaultLang: "en",
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(),
    provideTranslocoLocale(),
  ],
};
